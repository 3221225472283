@media print {
  body * {
    visibility: hidden;
  }
  @page {
    margin-bottom: 0;
    /* this affects the margin in the printer settings */
  }
  #divToDownload, #divToDownload * {
    visibility: visible;
  }
  #divToDownload {
    position: absolute;
    left: 0;
    top: 0;
  }
  .spaceR {
    margin-right: 10px;
  }
  .center-block {
    margin: auto;
    display: block;
  }
  .parent {
    display: grid;
    place-items: center;
  }
}