.center-component {
    position: absolute;
    top: 30%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.center-block {
    margin: auto;
    display: block;
}

.parent {
    display: grid;
    place-items: center;
}

@media (max-width: 767px) {
    .hidden-divice {
        display: none;
    }
}

@media (min-width: 767px) {
    .hidden-pc {
        display: none;
    }
}