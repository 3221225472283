body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
  background-color: #f4f8fd;
}

a {
  text-decoration: none;
  color: #e96b56;
}

a:hover {
  color: #e6573f;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}
.center {
  position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate3d(-50%, -50%, 0);
    -moz-transform:translate3d(-50%, -50%, 0);
    transform:translate3d(-50%, -50%, 0);
}


@import "bootstrap/dist/css/bootstrap.css";
