.application{
   /* background-color: #f8f9fa; */
  padding-bottom: 0px;
}
/* .bg-menu{
   background-color: #f8f9fa !important; 
} */


@media (min-width: 767px) {
   .spin-loader {
      display: grid;
      place-items: center;
      margin-top: 20%;
    }
 }

 @media (max-width: 767px) {
   .spin-loader {
      display: grid;
      place-items: center;
      margin-top: 50%;
    }
 }


 @media (max-width: 767px) {
   .hidden-device {
     display: none;
   }
 }
 
 @media (min-width: 767px) {
   .hidden-pc {
     display: none;
   }
 }