.body-pr {
  font-family: "Open Sans", sans-serif;
}

.table-result {
  /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15); */
  background: #fff;
  transition: all ease-in-out 0.3s;
  border-top: 3px solid #88a1e7 !important;
  border-radius: 4px;
  width: 103%;
  margin-left: -10px;
}

.btn-get {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 10px;
  border-radius: 10px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #567de9;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 1px solid #567de9;
  /* background: white; */
}

.btn-get:hover {
  font-size: 18px;
  background: transparent;
  color: #567de9;
  text-decoration: none;
}

.btn-get1 {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 10px;
  border-radius: 10px;
  transition: 0.5s;
  line-height: 1;
  color: #567de9;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 1px solid #567de9;
  /* background: white; */
}

.btn-get1:hover {
  /* font-size: 18px; */
  background: transparent;
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 769px) {
  .icon-boxes {
    position: relative;
    z-index: 2;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 768px) {
  .icon-boxes {
    position: relative;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    /* width: 94%; */
    /* margin:20px; */
  }
}

.icon-boxes .icon-box {
  padding: 40px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
  transition: all 0.3s ease-in-out;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 7px solid #25c348;
}

.icon-boxes .icon {
  margin: 0 auto 20px auto;
  display: inline-block;
  text-align: center;
}

.icon-boxes .icon i {
  font-size: 36px;
  line-height: 1;
  color: #f6b024;
}

.icon-boxes .title {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 15px;
}

.icon-boxes .title a {
  color: #05579e;
}

.icon-boxes .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
  color: #777777;
}

.icon-boxes .icon-box:hover i,
.icon-boxes .icon-box:hover h3 a,
.icon-boxes .icon-box:hover p {
  color: #fff;
}
