

a {
  color: #e96b56;
}

a:hover {
  color: #e6573f;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}



/*--------------------------------------------------------------

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e96b56;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0 20px 0;
  background: #f7f7f7;
  border-bottom: 1px solid #ededed;
  margin-bottom: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 700;
  color: #545454;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6e6e6e;
  content: "/";
}

/*--------------------------------------------------------------

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e96b56;
}

.about .content p:last-child {
  margin-bottom: 0;
}


/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.clients .owl-item img {
  opacity: 0.5;
  width: 80%;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .owl-item img:hover {
  filter: none;
  opacity: 1;
}

.clients .owl-nav, .clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.clients .owl-dot.active {
  background-color: #e96b56 !important;
}

