.button-up {
  display: inline-block;
  background: #4285f4;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 40px;
  border-radius: 5px;
  /* box-shadow: 0px 17px 10px -10px rgba(0,0,0,0.4); */
  cursor: pointer;
  transition: all ease-in-out 300ms;
  text-decoration: none;
}
