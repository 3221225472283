@media only screen and (min-width: 769px) {
  .featured {
    position: relative;
    z-index: 2;
    margin-top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
  }
}

@media only screen and (max-width: 768px) {
  .featured {
    position: relative;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: 94%;
  }
}

.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  border-top: 7px solid #FF8C00;
  /* width: 340px !important; */
}

.featured .icon-box i {
  color: #e96b56;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
}

.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}

.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured .icon-box:hover {
  background: #e96b56;
  transform: scale(1.08);
}

.featured .icon-box:hover i, .featured .icon-box:hover h3 a, .featured .icon-box:hover p {
  color: #fff;
}

/* Card Styles */

.card-sl {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-image img {
  max-height: 100%;
  max-width: 100%;
}

.card-action:hover {
  color: #fff;
  background: #E26D5C;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
  background: #fff;
  padding: 10px 15px;
}

.card-text {
  padding: 10px 15px;
  background: #fff;
  font-size: 14px;
  color: #636262;
}

.card-button {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  background-color: #1F487E;
  color: #fff;
}

.card-buttonPrint {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  background-color: #198754;
  color: #fff;
}

.card-button:hover {
  text-decoration: none;
  background-color: #1D3461;
  color: #fff;
}