#hero {
  width: 100%;
  background-color: rgba(59, 59, 59, 0.8);
  overflow: hidden;
  padding: 0;
  /*  margin-bottom: -120px; */
  position: relative;
  z-index: 1;
  margin-top: 0px;
}

#hero .carousel-item {
  width: 100%;
  height: 94.2vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center center;
}

#hero .carousel-item::before {
  content: '';
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  /*  padding-bottom: 120px; */
}

#hero .carousel-content {
  text-align: left;
}

#hero h2 {
  color: #2e2e2e;
  margin-bottom: 20px;
  font-size: 38px;
  font-weight: 700;
}

#hero h5 {
  color: #2e2e2e;
  /*  margin-bottom: 20px; */
}

#hero h2 span {
  color: #198754;
}

#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: arken(#545454, 15);
}

#hero .carousel-inner .carousel-item-next, #hero .carousel-inner .carousel-item-prev, #hero .carousel-inner .active.carousel-item-left, #hero .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
  color: #545454;
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #d8c3bf;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 180px;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #E53B3B;
  background: #E53B3B;
}

#hero .btn-get-started:hover {
  background: transparent;
  color: #E53B3B;
  text-decoration: none;
}

.btSapce {
  margin-bottom: 20px;
}



@media (MIN-width: 687px) {
  .intro {
    margin-right: 30%;
    margin-left: 10%;
  }
}

@media (max-width: 687px) {
  .intro {
    margin: 10%;
  }

  .sliderText {
    text-align: center;
    vertical-align: middle;
  }
  #hero .carousel-item {
    width: 100%;
    height: 80.2ch;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: center center;
  }
}