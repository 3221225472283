#header {
  min-height: 55px;
  transition: all 0.5s;
  z-index: 997;
  padding: 0 5% 0 5%;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
}

#header.header-scrolled {
  border-color: #fff;
  box-shadow: 0px 2px 15px rgba(18, 66, 101, 0.08);
}

#header .logo h1 {
  font-size: 20px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  /* border-left: 8px solid #789af8; */
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* .nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
} */

.nav-menu a {
  display: block;
  position: relative;
  color: #545454;
  padding: 13px 0 15px 25px;
  transition: 0.3s;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  color: #e96b56;
  text-decoration: none;
}

/* .nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 20px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
} */

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #E53B3B;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}


@media (max-width: 766px) {
  .hidden-divice {
      display: none;
  }
}

@media (min-width: 765px) {
  .hidden-pc {
      display: none;
  }
}