.body-pr {
  margin-top: 100px !important;
  margin: 10px;
  font-family: "Open Sans", sans-serif;
}

.text-leftP {
  margin-top: 50%;
}

.col1 {
  margin-left: 10px;
  text-align: right;
  margin-bottom: 20px;
  border-right: 3px solid #ff8c00;
}

.full-heightRegisto {
  padding: 0px 0px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  border-top: 3px solid #88a1e7;
}

.fields {
  max-width: 600px;
}

.affix {
  top: 20px;
  z-index: 9999 !important;
}

.btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ff8c00;
  background: #ff8c00;
}

.btn-get-startedC {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #e96b56;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ffffff;
  background: #ffffff;
}

.btn-get-started:hover {
  background: transparent;
  color: #25c348;
  text-decoration: none;
}

.btn-get-startedC:hover {
  background: transparent;
  color: #e96b56;
  border: 2px solid #e96b56;
  text-decoration: none;
}

.titleSpace{
  margin-bottom: 30px;
}


@media (max-width: 768px) {
  .col1 {
    display: none;
  }
}

.topSpace {
  padding-top: 22px;
}

.bottomSpace {
  padding-bottom: 44px;
}

.goRight {
  text-align: right;
}

.noBorders {
  border: 0px;
}

.Back {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  border: none;
  background-color: #4285f4;
  color: #fff;
  text-align: center;
  /* box-shadow: 2px 7px 22px -5px rgba(0, 0, 0, 0.4); */
  cursor: pointer;
  /* padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  padding-right: 14px; */
  transition: all ease-in-out 300ms;
}

@media (max-width: 576px) {
  .goRight {
    text-align: left;
  }
}


@media (min-width: 996px){
  .mycontent-left {
    border-right: 4px solid #e96b56;
    border-radius: 10px;
    margin-right: 25px;
    /* border-style: 150px; */
  }
}
