.button {
  position: fixed;
  bottom: 6%;
  right: 7.7%;
}

.speed {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  border: none;
  background-color: #4285f4;
  color: #fff;
  box-shadow: 2px 7px 22px -5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 19px;
  padding-right: 19px;
  transition: all ease-in-out 300ms;
}

.speed1 {
  padding: 50px;
  color: #fff;
}

.speed:hover {
  box-shadow: 0px 37px 20px -15px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -10px);
}

.main-body {
  overflow: auto;
}